body {
  background: rgb(0,80,138);
  background: radial-gradient(circle, rgba(0,80,138,0.9) 0%, rgba(23,40,72,0.9) 100%);
  font-family: 'Poppins';
  margin: 0;
}

.nav {
  padding-top: 20px;
}

.nav-item {
  padding-top: 30px;
}

.nav-item > a > svg {
  padding-right: 10px;
  width: 14px;
}

.nav-item > a {
  text-decoration: none;
  color: white;
}

.page-wrapper {
  color: white;
  padding-top: 30px;
  padding-left: 10px;
}

.google-visualization-controls-label {
  color:white;
}